<template>
    <div class="card shadow mb-5">
        <div class="card-body container-fluid overflow-auto">
            <div class="row">
                <div class="col-lg-6 input-group mb-3">
                    <input id="searchInput" type="text" v-model="searchQuery" v-on:keyup="filterCheckpointList" class="form-control" v-bind:placeholder="$t('searchOn')" aria-label="Search" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2">
                            <font-awesome-icon :icon="['fas','search']" size="1x" />
                        </span>
                    </div>
                </div>                
            </div>
            <b-table :busy="!this.isTableInitialized" show-empty striped hovers :items="filteredList" :fields="tableFields" table-class="mb-0 k4-table" thead-class="k4-table__thead sticky-top" tbody-class="k4-table__tbody" small>
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle mx-2"></b-spinner>
                        <strong>{{ this.$t("loading") }}...</strong>
                    </div>
                </template>
                
                <!-- Texts to show when the table is empty -->
                <template #empty>
                    <div class="text-center my-3">{{ $t("noDataToDisplay") }}</div>
                </template>
                <template #emptyfiltered>
                    <div class="text-center my-3">{{ scope.emptyFilteredText }}</div>
                </template>

                <!-- Display Data -->
                <template v-slot:cell(isPresent)="row">
                    <span v-if="row.item.isInAndOut">{{row.item.isPresent}}</span>
                    <span v-else>-</span>
                </template>
                <template v-slot:cell(startDt)="data">
                    <div class="text-nowrap"><span v-if="data.value">{{this.$DateTimeService.GetDateTimeFromISO(data.value, false)}}</span></div>
                </template>
                <template v-slot:cell(capacity)="data">
                    <span v-if="data.value > 0">{{data.value}}</span>
                    <span v-else>-</span>
                </template>
                <template v-slot:cell(accessControl)="data">
                    <span v-if="data.value"><font-awesome-icon :icon="['fas','check-square']" size="lg" /></span>
                    <span v-if="!data.value"><font-awesome-icon :icon="['fas','square']" size="lg" /></span>
                </template>
                <template v-slot:cell(devices)="data">
                    <span v-if="data.value > 0">{{data.value}}</span>
                    <span v-else>-</span>
                </template>
                <template v-slot:cell(action)="row">
                    <div class="text-nowrap">
                        <b-button variant="secondary" class="mr-2 btn-sm" @click="showDetails(row.item)" v-if="isAdmin()"><font-awesome-icon icon="gear" size="1x" class="mx-1" /></b-button>
                        <b-button variant="primary" class="mr-2 btn-sm" @click="showCheckIn(row.item)" v-if="isAdmin()"><font-awesome-icon icon="qrcode" size="1x" class="mx-1" /></b-button>
                        <b-button variant="success" class="btn-sm" @click="showAttendees(row.item)"><font-awesome-icon icon="user-group" size="1x" class="mx-1" /></b-button>
                    </div>
                </template>
            </b-table>
            <CheckpointDetails ref="detailsModal" :isReadOnly="this.event.isArchived"/>
            <BarcodeList ref="barcodes" />
            <ModalCheckEntriesList ref="checkInModal" />
        </div>
    </div>
</template>

<script>
import CheckpointDetails from './CheckpointDetails';
import BarcodeList from '../barcodes/barcodeList';
import ModalCheckEntriesList from '../checkentries/ModalCheckEntriesList';
import UserService from '../../services/user.service';
import { mapGetters } from 'vuex';

export default {
    name: 'CheckpointList',
    data : function() {
        return {
            isTableInitialized: false,
            searchQuery : '',
            filteredList: []
        }
    },
    watch : {
        checkpoints() {
            this.filterCheckpointList();
            this.isTableInitialized = true;
        }
    },
    methods : {
        filterCheckpointList : function() {
             this.filteredList = this.checkpoints.filter(item => {
                var itemText = item.id + '|' + item.name + '|' + item.category; 
                return itemText.toLowerCase().includes(this.searchQuery.toLowerCase())
            });
        },
        capacity : function(val){
            return (val > 0) ? val + ' pers.' : 'not provided';
        },
        showDetails : function(checkpoint) {
            this.$refs.detailsModal.id = checkpoint.id;
            this.$refs.detailsModal.showModal();
        },
        showCheckIn : function(checkpoint)  {
            this.$refs.checkInModal.type = 'CHECKPOINT',
            this.$refs.checkInModal.title = checkpoint.name;
            this.$refs.checkInModal.checkpointId = checkpoint.id;
            this.$refs.checkInModal.isAllowManualAdditionalScans = checkpoint.isAllowManualAdditionalScans;
            this.$refs.checkInModal.showModal();
        },
        showAttendees : function(checkpoint){
            if(checkpoint){
                this.$refs.barcodes.showModal();
                this.$refs.barcodes.checkpointId = checkpoint.id;
                this.$refs.barcodes.checkpointName = checkpoint.name;
            }
        },
        isAdmin : function(){
            return UserService.isAdmin();
        },
        showToast: function(title, body, variant = 'default'){
            this.$bvToast.toast(body, {
                title: title ,
                autoHideDelay: 5000,
                variant: variant,
                appendToast: false
            });
        }
    },
    computed: {
        ...mapGetters({
            event: "getEvent",
            checkpoints: 'getCheckpoints' // Map the getter to a local computed property
        }),
        tableFields: function() {
            return [
                { key : 'id', label: this.$t("id"), sortable : true},
                { key : 'name', label: this.$t("label"), sortable : true },
                { key : 'startDt', label: this.$t("start"), sortable : true, tdClass : 'text-center', thClass : 'text-center' },
				{ key : 'category', label: this.$t("category"), sortable : true, tdClass : 'text-center', thClass : 'text-center' },
                { key : 'accessControl', label: this.$t("accessControl"), sortable : true, tdClass : 'text-center', thClass : 'text-center' },
                { key : 'numberOfAttendees', label: this.$t("expected"), sortable : true, tdClass : 'text-center', thClass : 'text-center' },
                { key : 'alreadyCheckedIn', label: this.$t("checkedIn"), sortable : true, tdClass : 'text-center', thClass : 'text-center' },
                { key : 'isPresent', label: this.$t("expected") + " " + this.$t("present"), sortable : true, tdClass : 'text-center', thClass : 'text-center' },
                { key : 'capacity', label: this.$t("capacity"), sortable : true, tdClass : 'text-center', thClass : 'text-center' },
                { key : 'scans', label: this.$t("scans"), sortable: true, tdClass : 'text-center', thClass : 'text-center' },
                { key : 'devices', label: this.$t("devices"), sortable : true, tdClass : 'text-center', thClass : 'text-center' },
                { key : 'action', label: this.$t("actions"), sortable: false, tdClass : 'text-right', thClass : 'text-center' }            
            ];
        }
    },
    components : {CheckpointDetails, BarcodeList, ModalCheckEntriesList}
}
</script>
<style scoped>
    .k4-title{background-color: var(--main-k4-primary-color);color:var(--main-top-font-color);}
    .k4-selectable:hover{cursor: pointer;background-color: var(--main-menu-bg-color1); border-color: var(--main-menu-bg-color2);}
    .container-fluid{border-radius: 4px 5px/5px 0 0 ; overflow: hidden;}
</style>
<style>
    .k4-table{border-radius: 5px; overflow: hidden;}
    .k4-table td{vertical-align: sub !important;}
    .k4-table__thead {
        background-color: var(--primary-dark);
        color: var(--white); 
        position: sticky;
    }

    .k4-table__thead > tr > th {
        font-size: 1em !important;
    }

    .k4-table__tbody > tr > td {
        font-size: 0.9em !important;
    }
</style>