<template>
    <b-modal v-if="user" v-model="modalShow" :title="$t('edit') + ' ' + user.name" size="lg">
        <div class="container-fluid mb-2">
            <div class="row pb-2">
                <div class="col-lg-2">{{ $t("name")}}</div>
                <div class="col-lg-10">
                    <input type="text" class="form-control" v-model="user.name" disabled>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-lg-2">{{ $t("email")}}</div>
                <div class="col-lg-10">
                    <input type="text" class="form-control" v-model="user.email" disabled>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-lg-2">{{ $t("group")}}</div>
                <div class="col-lg-10">
                    <VueMultiselect
                        v-model="selectedGroup"
                        :options="groups"
                        :multiple="false"
                        :taggable="true"
                        @tag="addNewGroup"
                        :tag-placeholder="$t('createNewGroup')"
                        :placeholder="$t('searchGroup')"
                        label="name"
                        track-by="code"
                        :disabled="!this.canModifyGroup"
                    />
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-lg-2">{{ $t("role")}}</div>
                <div class="col-lg-10">
                    <div class="row">
                        <div v-for="role in roles" :key="role.id" class="col-lg-4">
                            <div class="card p-2 mb-1 k4-pointer text-center" :class="{'k4-card-selected' : isSelected(role, user.roles)}" @click="changeItem(role, user.roles)">
                                {{role.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="events" class="row pb-2">
                <div class="col-lg-2">{{ $t("events")}}</div>
                <div class="col-lg-10">
                    <div class="row">
                        <div v-for="event in events" :key="event.id" class="col-12">
                            <div class="card p-2 mb-1 k4-pointer text-center" :class="{'k4-card-selected' : isSelected(event, user.events)}" @click="changeItem(event, user.events)">
                                {{event.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="w-100">
                <button type="button" @click="modalShow = false" class="btn btn-outline-secondary mx-1">{{$t("cancel")}}</button>
                <button type="button" @click="$event => updateUser()" class="btn btn-success mx-1">{{$t("save")}}</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import EventService from '../../services/event.service';
    import UserService from '../../services/user.service';
    import utilMixin from '../../mixins/utils.mixins';
    import VueMultiselect from 'vue-multiselect'

    export default {
        name:'UpdateUser',
        mixins: [utilMixin],
        components: { VueMultiselect },
        data: function(){
            return{
                roles:[],
                events:[],
                user:{},
                modalShow:false,
                selectedGroup: null,
                groups: [] // Tag objects list
            }
        },
        created: function(){
            this.getAllGroups();

            UserService.getAllRoles("bo").then(
                resp => {
                    var response = resp.status ? resp : resp.response; 
                    if(response.status == 200){
                        this.roles = response.data;
                    }
                    else {
                        this.showToast(this.$t('error'), response.data, 'danger');
                    }
                }
            );
            
            if(this.isSuperAdmin){
                EventService.getAllEvents().then(
                    resp => {
                        var response = resp.status ? resp : resp.response; 
                        if(response.status == 200){
                            this.events = response.data;
                        } else {
                            
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }
                    }
                );
            }
            else {
                EventService.getUserEvents(this.connectedUserId).then(
                    resp => {
                        var response = resp.status ? resp : resp.response; 
                        if(response.status == 200){
                            this.events = response.data;
                        } else {
                            
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }
                    }
                );
            }
            
        },
        methods:{
            getAllGroups(){
                this.groups = [];
                return UserService.getAllGroups().then(
                    resp => {
                        var response = resp.status ? resp : resp.response;
                        if(response.status == 200) {
                            const promises = response.data.map(element => {
                                return new Promise((resolve) => {
                                    this.groups.push({ name: element.label, code: element.id });
                                    resolve();
                                });
                            });
                            
                            console.log(this.groups);
                            return Promise.all(promises);
                        } 
                        else {
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }

                        return response;
                    }
                );
            },
            assignGroupToUser(){
                let hasGroup = this.groups.some((group) => group.code === this.user.groupId);
                if(this.user.groupId && !hasGroup){
                    this.getAllGroups().then(() => {
                        this.selectedGroup = this.groups.find(group => group.code === this.user.groupId);
                    })
                }
                else {
                    this.selectedGroup = this.groups.find(group => group.code === this.user.groupId);
                }
            },
            addNewGroup (newGroupLabel) {
                newGroupLabel = newGroupLabel.toUpperCase();
                const tag = {
                    name: newGroupLabel,
                    code: this.groupsMaxId + 1
                }
                this.groups.push(tag);
                this.selectedGroup = tag;
            },
            showModal: function(user){
                this.user = user;
                this.assignGroupToUser();
                this.modalShow = !this.modalShow;
                
                //this.showToast(this.$t('error'), this.$t('noUserSelected'), 'danger');
            },
            updateUser:function(event){
                if(!this.user){
                    event.stopPropagation();
                    this.modalShow = false;
                    return;
                }
                
                UserService.updateUserEvents(this.user).then(
                    resp => {
                        var response = resp.status ? resp : resp.response; 
                        if(response.status != 200){
                            this.modalShow = false;
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }
                    }
                );

                if(this.isSuperAdmin){
                    let selectedGroupValue = (this.selectedGroup !== null && this.selectedGroup !== undefined) ? this.selectedGroup.name : null;

                    UserService.assignUserGroup(this.user.id, selectedGroupValue).then(
                        resp => {
                            var response = resp.status ? resp : resp.response;
                            this.modalShow = false;
                            if(response.status == 200){
                                    this.showToast(this.$t('savedUser'), response.data);
                                    this.$emit('update_ok');
                            }
                            else {
                                this.showToast(this.$t('error'), response.data, 'danger');
                            }
                        }
                    );
                }

                UserService.updateUserRoles(this.user).then(
                    resp => {
                        var response = resp.status ? resp : resp.response;
                        this.modalShow = false;
                        if(response.status == 200){
                                this.showToast(this.$t('savedUser'), response.data);
                                this.$emit('update_ok');
                        }
                        else {
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }
                    }
                );
            },
            changeItem : function(event, lstDest){
                var index = lstDest.findIndex(i => i.id == event.id);
                
                if(index < 0){
                    lstDest.push({id: event.id, name: event.name });
                } 
                else {
                    lstDest.splice(index, 1);
                }
            },
            isSelected : function (item, lstDest){
                if(lstDest){
                    return lstDest.findIndex(i => i.id == item.id)>=0;
                }
                return false;
            }
        },
        computed: {
            canModifyGroup(){
                return this.$store.state.auth.user.roles.includes("key4admin");
            },
            groupsMaxId(){
                return Math.max(this.groups.map(group => group.code));
            },
            isSuperAdmin(){
                return UserService.isSuperAdmin();
            },
            connectedUserId(){
                return UserService.getUserId();
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>