import { createStore } from 'vuex'

import EventService from '../services/event.service.js';
import CheckpointService from '../services/checkpoint.service.js';
import{ auth } from './auth.module.js';

// When you open several tabs of the same page in a web application that uses Vuex, 
// each tab operates independently and typically has its own instance of the store. 
// This means that changes to the store in one tab will not automatically propagate to the other tabs. 
// Each tab maintains its own separate state and doesn't directly override the store in other tabs.
const store = createStore({
  state: {
    language: "en",
    isSmallScreen: window.innerWidth <= 768,
    event: {},
    checkpoints: []
  },
  getters: {
    getLanguage: (state) => {
      return state.language;
    },
    getIsSmallScreen: (state) => {
      return state.isSmallScreen
    },
    getEvent: state => {
      return state.event;
    },
    getCheckpoints: state => {
      return state.checkpoints;
    },
    getError: state => {
      return state.error;
    }
  },
  mutations: {
    UPDATE_LANG(state, lang) {
        state.language = lang;
    },
    setIsSmallScreen(state, isSmallScreen){
      state.isSmallScreen = isSmallScreen;
    },
    setEvent(state, event){
      state.event = event;
    },
    setCheckpoints(state, checkpoints){
      state.checkpoints = checkpoints;
    }
  },
  // Actions are typically used to perform tasks like making API calls, 
  // handling complex asynchronous operations, or executing multiple mutations based on 
  // certain conditions.
  actions: {
    changeLanguage({ commit }, payload) {
        payload.i18n.locale = payload.lang;
        commit('UPDATE_LANG', payload.lang);
    },
    fetchEvent(context, eventId) {
      EventService.getEventDetails(eventId).then(
          resp => {
              var response = resp.status ? resp : resp.response;
              if(response.status == 200){
                context.commit('setEvent', response.data);
              } 
              else {
                throw new Error("Oops! It seems we couldn't retrieve the event information at the moment.");
              }
          }
      ).catch(error => {
        throw error;
      });
    },
    fetchCheckpoints(context){
      CheckpointService.getAllCheckpoints(context.state.event.id).then(
          resp => {
              // Handle the response
              var response = resp.status ? resp : resp.response;
              if(response.status == 200){
                context.commit('setCheckpoints', response.data);
              }
              else {
                throw new Error("Oops! It seems we couldn't retrieve the checkpoints' data at the moment.");
              }
          }
      ).catch(
          error => {
            throw error;
          }
      )
    },
    updateEvent({ commit }, event) {
      commit('setEvent', event);
    }
  },
  modules: {
    auth
  },
});

export default store;
