<template>
    <b-modal v-if="checkpoint" v-model="modalShow" v-bind:title="$t('detailsFor') + checkpoint.name" size="lg">
        <div class="container-fluid">
            <div class="row">
                <div class="col-4">{{ $t("name") }}</div>
                <div class="col-8"><input type="text" class="form-control" disabled v-model="checkpoint.name"></div>
            </div>
            <div class="row">
                <div class="col-4">{{ $t("category") }}</div>
                <div class="col-8"><input type="text" class="form-control" disabled v-model="checkpoint.category"></div>
            </div>
            <div class="row">
                <div class="col-4">{{ $t('startDate') }}</div>
                <div class="col-8">
                    <input v-if="checkpoint.startDt" type="text" class="form-control" disabled v-bind:value="this.$DateTimeService.GetDateTimeFromISO(checkpoint.startDt, false)">
                    <input v-if="!checkpoint.startDt" type="text" class="form-control" disabled >
                </div>
            </div>
            <div class="row">
                <div class="col-4">{{ $t('endDate') }}</div>
                <div class="col-8">
                    <input v-if="checkpoint.endDt" type="text" class="form-control" disabled v-bind:value="this.$DateTimeService.GetDateTimeFromISO(checkpoint.endDt, false)">
                    <input v-if="!checkpoint.endDt" type="text" class="form-control" disabled >
                </div>
            </div>
            <div class="row">
                <div class="col-4">{{ $t('location') }}</div>
                <div class="col-8"><input type="text" class="form-control" disabled v-model="checkpoint.location"></div>
            </div>
            <div class="row">
                <div class="col-4" for="isWaitForSignature">{{ $t('isWaitForSignature') }}</div>
                <div class="col-8">
                    <b-form-checkbox v-model="checkpoint.isWaitForSignature" size="lg" disabled></b-form-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-4">{{ $t('allowManualAdditionalScans') }}</div>
                <div class="col-8 text-primary">
                    <b-form-checkbox v-model="checkpoint.isAllowManualAdditionalScans" size="lg" :disabled="this.isReadOnly"></b-form-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-4">{{ $t('resultDisplayDuration') }}</div>
                <div class="col-8 input-group">
                    <input type="number" class="form-control" v-model="checkpoint.resultDisplayDuration">
                    <div class="input-group-append"><span class="input-group-text">{{ $t('seconds') }}</span></div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">{{ $t('capacity') }}</div>
                <div class="col-8 input-group">
                    <input type="text" class="form-control" disabled v-model="checkpoint.capacity">
                    <div class="input-group-append"><span class="input-group-text">{{ $t('people') }}</span></div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">{{ $t('defaultNumberAllowed') }}</div>
                <div class="col-8"><input type="text" class="form-control" disabled v-model="checkpoint.defaultNumberOfAllowedCheckedIn"></div>
            </div>
            <div class="row">
                <div class="col-4">{{ $t('syncFirst') }}</div>
                <div class="col-8 text-primary">
                    <b-form-checkbox v-model="checkpoint.syncAtFirst" size="lg" :disabled="this.isReadOnly"></b-form-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-4">{{ $t('syncFrequency') }}</div>
                <div class="col-8 input-group">
                    <input type="number" min="5" class="form-control" v-model="checkpoint.syncFrequency" :disabled="this.isReadOnly">
                    <div class="input-group-append"><span class="input-group-text">{{ $t('seconds') }}</span></div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">{{ $t('accessControl') }}</div>
                <div class="col-8">
                    <b-form-checkbox v-model="checkpoint.accessControl" size="lg" disabled></b-form-checkbox>
                </div>
            </div>
            <div class="row" v-if="checkpoint.accessControl">
                <div class="col-4">{{ $t('accessUnknowBarcode') }}</div>
                <div class="col-8">
                    <b-form-checkbox v-model="checkpoint.accessIfUnknowBarCode" size="lg" disabled></b-form-checkbox>
                </div>
            </div>
            <div class="row" v-if="checkpoint.accessControl">
                <div class="col-4">{{ $t('allowedRegistration') }}</div>
                <div class="col-8">
                    <b-form-checkbox v-model="checkpoint.allowedRegistration" size="lg" disabled></b-form-checkbox>
                </div>
            </div>
            <div class="row" v-if="checkpoint.accessControl">
                <div class="col-4">{{ $t('isInAndOut') }}</div>
                <div class="col-8">
                    <b-form-checkbox v-model="checkpoint.isInAndOut" size="lg" disabled></b-form-checkbox>
                </div>
            </div>
            <div class="row" v-if="checkpoint.accessControl">
                <div class="col-4">{{ $t('openAccess') }}</div>
                <div class="col-8" >
                    <input type="text" v-if="checkpoint.dateTimeUTCOpenForAll != null" class="form-control" disabled v-bind:value="this.$DateTimeService.GetDateTimeFromISO(checkpoint.dateTimeUTCOpenForAll, true)">
                    <input type="text" v-else class="form-control" disabled >
                </div>
            </div>
            <div class="row" v-if="checkpoint.accessControl">
                <div class="col-4">{{ $t('lastUpdate') }}</div>
                <div class="col-8" >
                    <input type="text" v-if="checkpoint.lastUpDate != null" class="form-control" disabled v-bind:value="this.$DateTimeService.GetDateTimeFromISO(checkpoint.lastUpDate, true)">
                    <input type="text" v-else class="form-control" disabled >
                </div>
            </div>
            <div class="row">
                <div class="col-4">{{ $t('photoDisplay') }}</div>
                <div class="col-8">
                    <select class="custom-select col-md-4 col-sm-12" v-model="checkpoint.photoDisplayOption" :disabled="this.isReadOnly">
                        <option value="NONE">{{ $t('photoDisplayNone')}}</option>
                        <option value="PRELOAD">{{ $t('photoDisplayPreload')}}</option>
                        <option value="ONLOAD">{{ $t('photoDisplayOnLoad')}}</option>
                    </select>
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="w-100">
                <button type="button" @click="modalShow = false" class="btn btn-outline-secondary mx-1">{{$t("cancel")}}</button>
                <button v-if="!isReadOnly" type="button" @click="save" class="btn btn-success mx-1">{{$t('save')}}</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import CheckpointService from '../../services/checkpoint.service';
import utilMixin from '../../mixins/utils.mixins';

export default {
    name : 'checkpointDetails',
    props: ["isReadOnly"],
    mixins: [utilMixin],
    data : function(){
        return {
            id : 0,
            checkpoint:{},
            modalShow : false
        };
    },
    computed : {
        eventId : function() {
            return this.$route.params.id;
        }
    },
    watch : {
        id : function(val){
            if(val > 0 && this.eventId > 0 ){
                this.refreshCheckpoint();
            }
        },
    },
    methods:{
        showModal : function() {
            this.modalShow = true;
        },
        refreshCheckpoint : function(){
            if(this.id == 0 || this.eventId == 0 ){
                return;
            }
            CheckpointService.getCheckpoint(this.eventId, this.id).then(
                resp =>{
                    var response = resp.status ? resp : resp.response; 
                    if(response.status == 200){
                        this.checkpoint = response.data;
                    } 
                    else {
                        this.showToast(this.$t('error'), response.data, 'danger');
                    }
                }
            ).catch(
                error => {
                    this.showToast(this.$t('error'), error, 'danger');
                }
            );
        },
        save : function(){
            var model = {
                id : this.checkpoint.id,
                isAllowManualAdditionalScans : this.checkpoint.isAllowManualAdditionalScans,
                resultDisplayDuration : this.checkpoint.resultDisplayDuration,
                syncAtFirst : this.checkpoint.syncAtFirst,
                syncFrequency : this.checkpoint.syncFrequency,
                photoDisplayOption : this.checkpoint.photoDisplayOption
            }

            if (model.syncFrequency < 5) {
                this.checkpoint.syncFrequency = 5;
                model.syncFrequency = 5;
            }

            CheckpointService.putCheckpoint(model, this.eventId).then(
                resp =>{
                    var response = resp.status ? resp : resp.response; 
                    if(response.status == 200){
                        this.modalShow = false;
                        this.showToast(this.$t('saveCheckpoint'), response.data);
                    } 
                    else {
                        
                        this.showToast(this.$t('error'), response.data, 'danger');
                    }
                }
            ).catch(
                error => {
                    
                    this.showToast(this.$t('error'), error, 'danger');
                }
            );
            this.$emit('save_ok');
        }
    }
}
</script>
<style scoped>
.container-fluid .row{margin-bottom:5px;}
</style>