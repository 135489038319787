import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL_BASE + 'api/admin/events/[eventId]/checkpoints/';

class CheckpointService {
    getAllCheckpoints(eventId){
        if(!eventId){
            throw new Error('Error: eventId is null.');
        }

        return axios.get(API_URL.replace('[eventId]', eventId), { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getCheckpoint(eventId,checkpointId){
        return axios.get(API_URL.replace('[eventId]',eventId) + checkpointId, { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getUserCheckpoint(user){
        return axios.get(API_URL.replace('[eventId]',user.eventId) + 'byuser/' + user.id, { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getRobotCheckpoint(user){
        return axios.get(API_URL.replace('[eventId]', user.eventId) + 'byrobot/' + user.id, { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    importCheckpoints(eventId){
        if(!eventId){
            throw new Error('Error: eventId is null.');
        }

        var url = (API_URL + 'import/').replace('[eventId]', eventId);

        return axios.get(url,{ headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    putCheckpoint(checkpoint, eventId){
        if(!eventId || eventId <= 0){
            throw new Error('Error: eventId is null.');
        }
        if(!checkpoint){
            throw new Error('Error: No Checkpoint to update.');
        }

        var url = API_URL.replace('[eventId]', eventId);
        return axios.put(url,checkpoint,{ headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    putUserCheckpoints(userCheckpoints, eventId){
        if(!eventId){
            throw new Error('Error: eventId is null.');
        }
        if(!userCheckpoints){
            throw new Error('Error: No userCheckpoints to update.');
        }

        var url = (API_URL + 'byuser/add').replace('[eventId]', eventId);
        return axios.put(url,userCheckpoints,{ headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    putRobotCheckpoints(robotCheckpoints, eventId){
        if(!eventId){
            throw new Error('Error: eventId is null.');
        }
        if(!robotCheckpoints){
            throw new Error('Error: No userCheckpoints to update.');
        }

        var url = (API_URL + 'byrobot/add').replace('[eventId]', eventId);
        return axios.put(url,robotCheckpoints,{ headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

}

export default new CheckpointService;