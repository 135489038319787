<template>
    <div>
        <div class="d-flex align-items-center justify-content-end">
            <b-button variant="primary" class="mr-2" @click="modalShow = !modalShow"><font-awesome-icon :icon="['fas','plus']" /> Add user</b-button>
        </div>
        
        <b-modal v-model="modalShow" v-bind:title="$t('createUser')" size="lg">
            <div class="container-fluid">
                <div class="row pb-2">
                    <div class="col-lg-3">{{ $t("name")}}</div>
                    <div class="col-lg-9">
                        <input type="text" v-model="newUser.Name" class="form-control" />
                    </div>
                </div>
                <div class="row pb-2">
                    <div class="col-lg-3">{{ $t("pinCode")}}</div>
                    <div class="col-lg-9">
                        <input type="numeric" min="1000" max="9999" v-model="newUser.Pin" class="form-control" />
                    </div>
                </div>
                <div class="row pb-2" v-if="IsRobotEnabled == true">
                    <div class="col-lg-3">{{ $t("UserType")}}</div>
                    <div class="col-lg-9">
                        <div style="width:200px;">
                            <select class="custom-select" v-model="newUser.IsRobotUser">
                                <option :value="false" selected>{{ $t('RegularUser')}}</option>
                                <option :value="true">{{ $t('RobotUser')}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row pb-2">
                    <div class="col-lg-3">{{ $t("role")}}</div>
                    <div class="col-lg-9">
                        <div class="row">
                            <div class="col-lg-4" v-for="(role, index) in roles" v-bind:key="index" v-on:click="changeRole(role)">
                                <div class="card p-2 text-center" :class="{'k4-card-selected' : isSelected(role)}">
                                    {{role.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div v-if="errorMessage.length" class="col-12 alert alert-danger">
                        <div v-for="(mess,index) in errorMessage" v-bind:key="index">{{mess}}</div>
                    </div>
                </div>
            </div>

            <template #modal-footer>
                <div class="w-100">
                    <button type="button" @click="modalShow = false" class="btn btn-outline-secondary mx-1">{{$t("cancel")}}</button>
                    <button type="button" @click="$event => createUser()" class="btn btn-success mx-1">{{$t('create')}}</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import UserService from '../../services/user.service'
import utilMixin from '../../mixins/utils.mixins';
import { mapGetters } from 'vuex';

export default {
    name:"AddPinUser",
    mixins: [utilMixin],
    data: function() { 
        return{
            modalShow : false,
            roles: [],
            errorMessage: [],
            IsRobotEnabled: false,
            newUser:{
                Name: '', 
                Pin: this.getRandomInt(1000, 9999),
                EventId: 0,
                Roles: [],
                IsRobotUser : false
            },
        }
    },
    computed : {
        ...mapGetters({
            event: 'getEvent'
        }),
        eventRouteId : function() {
            return this.$route.params.id;
        }
    },
    props:['eventId'],
    created : function(){ 
        UserService.getAllRoles("front").then(
            resp => {
                var response = resp.status ? resp : resp.response; 
                if(response.status == 200){
                    this.roles = response.data;

                    if(this.roles.length>0){
                        var role = this.roles.find(r => r.name == 'deviceUser');
                        if(role) {this.newUser.Roles.push(role);}
                    }

                } 
                else {
                    this.showToast('Error', response.data, 'danger');
                }
            }
        )
    },
    watch:{
        modalShow : function(val){
            if(val){
                this.newUser.EventId = this.event.id;
                this.newUser.Pin = this.getRandomInt(1000, 9999);
                this.IsRobotEnabled = this.event.acceptRobot;
            }
        }
    },
    methods:{
        createUser: function(event){
            if(event){
                event.preventDefault();
            }
            
            if(!this.validForm()){
                this.modalShow = false;
                return;   
            }
            this.errorMessage = [];
            UserService.putPinUser(this.newUser).then(
                valid => {
                    var response = valid.status ? valid : valid.response;
                    this.modalShow = false;
                    if(response.status == 200){
                        
                        this.showToast('Created user',this.newUser.Name + ' has been created.');
                        this.newUser.Name='';
                        this.$emit('create_ok');
                    } 
                    else {
                        this.errorMessage.push(response.data);
                    }
                })
                .catch(error => {
                    this.modalShow = false;
                    
                    this.showToast('Error - ', error);
                }
            );
        },
        validForm: function() {
            this.errorMessage=[]; 

            if(!this.newUser.Name)
                this.errorMessage.push('Please enter a Name.');
            if(this.newUser.Roles.length<=0)
                this.errorMessage.push('Please select at least one role.');

            return this.errorMessage.length==0;
        },
        changeRole: function(role){
            var index = this.newUser.Roles.findIndex(r => r.id == role.id);
            if(index<0){
                this.newUser.Roles.push(role);
            }else{
                this.newUser.Roles.splice(index,1);
            }
        },
        isSelected:function(role){
            var index = this.newUser.Roles.findIndex(r => r.id == role.id);
            return index >= 0;
        },
        getRandomInt: function(min, max){
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min;
        }
    }
}
</script>
