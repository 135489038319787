import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueExcelXlsx from "vue-excel-xlsx"
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import DateTimeService  from "./services/datetime.service.js";
import { createHead } from 'unhead'

// Localisation
import { createI18n } from 'vue-i18n'
import { ENGLISH_TRANSLATIONS } from './assets/localisation/en';
import { FRENCH_TRANSLATIONS } from './assets/localisation/fr';

import '../public/style/bootstrap.key4.css';
import '../public/style/font-awesome.key4.css';
import '../public/style/key4.css';
import 'v-calendar/style.css';
import '@vuepic/vue-datepicker/dist/main.css'

//#region FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserCircle, faSearch, faPlus, faPowerOff, faTachometerAlt, faHome, faCalendar, faQuestionCircle, faUsers, faTrashAlt, faPen, faSquare, faCheckSquare, faPlay, faStop, faTasks, faSignOutAlt, faUser, faAngleDown, faHistory, faTimes, faInfoCircle, faAddressBook, faChartPie, faChartBar, faClipboardCheck, faChevronLeft, faChevronRight, faFileExcel, faSyncAlt, faMinusCircle, faMinus, faFilePdf, faRobot, faList, faAddressCard, faTriangleExclamation, faFileCircleCheck, faFileCircleXmark, faLocationDot, faIdBadge, faUserCheck, faGear, faUpload, faQrcode, faUserGroup, faTowerBroadcast, faCircleLeft, faEye, faEyeSlash, faBars, faArrowLeft, faLanguage, faBook, faDownload, faBoxArchive, faRotate } from '@fortawesome/free-solid-svg-icons'

library.add(faUserCircle, faPowerOff, faTachometerAlt, faHome, faCalendar, faQuestionCircle, faUsers, faSearch, faPlus, faTrashAlt, faPen, faSquare, faCheckSquare, faPlay, faStop, faTasks, faSignOutAlt, faUser, faAngleDown, faHistory, faTimes, faInfoCircle, faAddressBook, faChartPie, faChartBar, faClipboardCheck, faChevronLeft, faChevronRight, faFileExcel, faSyncAlt, faMinusCircle, faMinus, faFilePdf, faRobot, faList, faAddressCard, faTriangleExclamation, faFileCircleCheck, faFileCircleXmark, faLocationDot, faIdBadge, faUserCheck, faGear, faUpload, faQrcode, faUserGroup, faTowerBroadcast, faCircleLeft, faEye, faEyeSlash, faBars, faArrowLeft, faLanguage, faBook, faDownload, faBoxArchive, faRotate);
//#endregion

// ===== Configuring instances =====
// Create i18n instance with options
const TRANSLATIONS = {
  en: ENGLISH_TRANSLATIONS,
  fr: FRENCH_TRANSLATIONS
};
const i18n = createI18n({
  locale: 'en',
  messages: TRANSLATIONS,
});

// Adding custom JS files as plugins so we can use global functions within the app
const plugins = {
  install(){
    app.DateTimeService = DateTimeService;
    app.config.globalProperties.$DateTimeService = DateTimeService;
  }
}

// ===== Create a vue root instance =====
export const app = createApp(App);
createHead();

// ===== Configuring app-scoped assets =====
app.component('font-awesome-icon', FontAwesomeIcon);

// ===== Install instances to make the whole app aware of usable libraries =====
app.use(store);
app.use(router);
app.use(BootstrapVue);
app.use(i18n);
app.use(VueExcelXlsx);
app.use(plugins);

app.config.globalProperties.$apiBaseUrl = 'TEST ME'

// ===== Mount =====
app.mount('#app');
