<template>
<div class="k4-page">
    <section class="row">
        <div class="col">
            <div class="title">
                <span class="text-center align-middle pointer-click mr-2" v-bind:title="$t('back')" v-on:click="goToEvent(eventId)">
                    <font-awesome-icon class="k4-global-backIcon" icon="circle-left" size="2x"/>
                </span>
                <h2 class="text-primary-dark d-inline mb-0 align-middle">{{ $t("statistics") }} // {{event.name}}</h2>
            </div>
        </div>
    </section>
    <b-button variant="excel" class="btn-statistics float-md-end" @click="getStatistics()"><font-awesome-icon :icon="['fas','file-excel']" size="1x" /> {{ $t('statisticsExport') }}</b-button>
    <SectionComponent v-bind:title="$t('generalStats')" icon="chart-pie">
        <StatEvent v-if="event" :event="event" />
    </SectionComponent>
    <SectionComponent v-bind:title="$t('rushHour')" icon="chart-bar">
        <div class="row pt-4">
            <div class="col-12">
                <div class="card shadow">
                    <div class="card-body">
                        <div class="row k4-body__row">
                            <div class="col-xl-8 col-12">
                                <div class="container d-flex justify-content-center align-items-center h-100" ref="sizeObserver">
                                    <RushHourChart v-if="renderComponent" :checkpoints="this.selectedCheckpoints"/>
                                </div>
                            </div>
                            <div class="col-xl-4 col-12">
                                <div class="container h-100 overflow-hidden">
                                    <div class="container-fluid overflow-auto">
                                        <h4 class="mb-3 align-middle">{{ $t('rushCheckpoint') }}</h4>
                                        <div style="overflow: auto;" :style="{'max-height': `${filterSectionHeight}px`}">
                                            <div class="card-body container-fluid" v-if="!checkpointList.length || checkpointList.length == 0">
                                                <div class="text-center p-3"><span class="spinner-border spinner-border-sm mr-3"></span>{{ $t('loading') }}</div>
                                            </div>
                                            <b-form-checkbox-group v-model="selectedCheckpoints">
                                                <b-form-checkbox style="display: block; font-size: 14px;" @input="updateButton()" class="col-0" v-for="(checkpoint, index) in checkpointList" :key="index" :value="checkpoint.id" :for="checkpoint.name" switch>{{checkpoint.name}}</b-form-checkbox>
                                            </b-form-checkbox-group>
                                        </div>
                                    </div>
                                    <div class="text-nowrap row">
                                        <b-button v-if="buttonRevert" variant="success" class="col mx-1" style="margin-top: 20px;" @click="checkAll()"><font-awesome-icon :icon="['fas','plus']" size="1x" />{{ $t('selectAll') }}</b-button>
                                        <b-button v-else variant="danger" class="col mx-1" style="margin-top: 20px;" @click="uncheckAll()"><font-awesome-icon :icon="['fas','minus']" size="1x" />{{ $t('rushRemoveSelection') }}</b-button>
                                        <b-button variant="primary" class="col mx-1" style="margin-top: 20px;" @click="forceRerender()"><font-awesome-icon :icon="['fas','sync-alt']" size="1x" />{{ $t('update') }}</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </SectionComponent>
</div>
</template>

<script>
import UserService from '../services/user.service';
import StatisticsService from '../services/statistics.service';

import StatEvent from '../components/events/StatEvent';
import SectionComponent from '../components/SectionComponent';

import utilMixin from '../mixins/utils.mixins';
import RushHourChart from '../components/charts/RushHourChart.vue'
import { mapGetters, mapActions } from 'vuex';

export default {
    name:"StatisticsView",
    mixins: [utilMixin],
    data: function (){
        return {
            checkpointList: [],
            selectedCheckpoints: [],
            renderComponent: true,
            buttonRevert: true,
            filterSectionHeight : 0
        }
    },
    computed: {
        eventId: function() {
            return this.$route.params.id;
        },
        ...mapGetters({
            event: 'getEvent',
            checkpoints: 'getCheckpoints'
        })
    },
    beforeMount: function(){
        this.initEvent();
    },
    mounted : function(){
        window.addEventListener("resize", this.matchHeight);
        this.matchHeight();
    },
    unmounted: function(){
        window.removeEventListener("resize", this.matchHeight);
    },
    updated: function(){
        this.matchHeight();
    },
    watch: {
        event(){
            try 
            {
                this.fetchCheckpoints();
            }
            catch(error) {
                this.showToast('error', error.message, 'danger');
            }
        },
        checkpoints(newCheckpoints){
            this.checkpointList = Array.from(newCheckpoints); // Deep copy of the list because the sort can modify the list and trigger the watch twice
            this.sortCheckpointsByName();
        }
    },
    methods: {
        ...mapActions(["fetchEvent", "fetchCheckpoints"]),
        initEvent(){
            if(Object.keys(this.event).length === 0){
                try {
                    this.fetchEvent(this.eventId);
                } 
                catch (error) {
                    this.showToast('error', error.message, 'danger');
                    this.$router.push({ name: "Events" });
                }
            }
            else {
                this.checkpointList = Array.from(this.checkpoints);
            }
        },
        goHome: function () {
            this.$router.replace({ name: "Root" });
        },
        goToEvent: function(id){
            this.$router.replace({ name:  "Event" });
            this.$router.push({ name: 'Event', params: { id: id } });
        },
        forceRerender: function() {
            // Remove my-component from the DOM
            this.renderComponent = false;

            this.$nextTick(() => {
                // Add the component back in
                this.renderComponent = true;
            });
        },
        checkAll: function() {
            for (let i = 0; i < this.checkpointList.length; i++){
                this.selectedCheckpoints.push(this.checkpointList[i]["id"]);
            }
            this.buttonRevert = !this.buttonRevert;
        },      
        uncheckAll: function() {
            this.selectedCheckpoints = [];
            this.buttonRevert = !this.buttonRevert;
        },             
        updateButton: function() {
            if (this.selectedCheckpoints.length > 0){
                this.buttonRevert = false;
            }
            else{
                this.buttonRevert = true;
            }
        },
        sortCheckpointsByName : function(){
            this.checkpointList.sort((a, b) => a.name.localeCompare(b.name));
        },
        matchHeight () {
            setInterval(() => {
                //console.log('sizeObserver : ' + (this.$refs.sizeObserver ? this.$refs.sizeObserver.offsetHeight : 0));
                this.filterSectionHeight = this.$refs.sizeObserver ? this.$refs.sizeObserver.offsetHeight - 100 : 0;               
            }, 100);
        },
        isAdmin: function() {
            var isAdmin = UserService.isAdmin();
            return isAdmin ? isAdmin : false ;
        },
        getStatistics: function() {
            this.showToast("Statistics", "Exporting statistics..");
            StatisticsService.getStatistics(this.eventId).then(
                resp => {
                    var response = resp.status ? resp : resp.response; 
                    if(response.status == 200){                
                        this.showToast("Statistics", "Export completed! Downloading now..");

                        // Converting to XLS 
                        var contentType = 'application/vnd.ms-excel';
                        var blob1 = this.b64toBlob(response.data, contentType);
                        var blobUrl1 = URL.createObjectURL(blob1);

                        // Starting the download
                        var a = document.createElement('a');
                        a.href = blobUrl1;
                        a.download = 'KEY4ACC_Statistics.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(blobUrl1);
                    } else {
                        
                        this.showToast(this.$t('error'), response.data, 'danger');
                    }
                },
                error => {
                    
                    this.showToast(this.$t('error'), error.data, 'danger');
                }
            ).catch(
                error => {
                    
                    this.showToast(this.$t('error'), error, 'danger');
                }
            );
        },
        b64toBlob: function(b64Data, contentType, sliceSize) {
            contentType = contentType || '';
            sliceSize = 512;

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);
                var byteNumbers = new Array(slice.length);

                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, {type: contentType});
            return blob;
        }
    },
    components : {StatEvent, SectionComponent, RushHourChart}
}
</script>
<style scoped>
    .k4-body__row {
        min-height: 70vh;
    }

    .btn-statistics{
        margin-top:40px;
        position: relative;
    }
</style>