<template>
    <div class="k4-page">
        <div class="row align-items-center">
            <div class="col-sm-12 col-md-6">
                <h2 class="k4-global-pageTitle mb-0 title">{{ $t('selectEvent') }}</h2>
            </div>

            <div class="col-sm-12 col-md-6 d-flex align-items-center">
                <form class="my-auto align-items-center flex-fill">
                    <div class="input-group input-group-lg input-group-icon icon-right">
                        <input id="searchInput" type="text" class="form-control" v-bind:placeholder="$t('search')" v-model="searchQuery" v-on:keyup="filterEventList" @keydown.enter.prevent>
                        <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon2">
                                <font-awesome-icon icon="search" />
                            </span>
                        </div>
                    </div>
                </form>

                <AddEvent v-if="isAdmin" class="mx-3" @import_ok="refreshEventsList" />
            </div>
        </div>

        <div v-if="this.filteredList.length > 0" class="my-3">
            <section v-if="this.activeEvents.length > 0" class="row my-2">
                <h3 class="text-black text-center my-3">ACTIVE EVENTS</h3>
                <div class="col-xl-4 col-lg-6 mb-3" role="button" v-for="event in activeEvents" v-bind:key="event.id">
                    <div class="card shadow-sm h-100 k4-eventCard" v-on:click="goToEvent(event.id)">
                        <div class="card-body">
                            <div class="k4-eventName h-50"><strong>{{event.name}}</strong></div>
                            <div class="d-flex justify-content-between align-items-center h-50 my-1">
                                <div class="my-1">{{ $t('startDate') }} : {{this.$DateTimeService.GetDateFromISO(event.startDate)}}</div>
                                <div class="badge" :class="{'badge-warning' : event.eventSyncState == 'NOT_SYNC', 'badge-primary': event.eventSyncState == 'PROGRESS_SYNC', 'badge-success': event.eventSyncState == 'SYNC' }" >{{eventSynchStateText(event)}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section v-if="this.archivedEvents.length > 0" class="row my-2">
                <h3 class="text-black text-center my-3">ARCHIVED EVENTS</h3>
                <div class="col-xl-4 col-lg-6 mb-3" role="button" v-for="event in archivedEvents" v-bind:key="event.id">
                    <div class="card shadow-sm h-100 k4-eventCard" v-on:click="goToEvent(event.id)">
                        <div class="card-body">
                            <div class="k4-eventName h-50"><strong>{{event.name}}</strong></div>
                            <div class="d-flex justify-content-between align-items-center h-50 my-1">
                                <div class="my-1">{{ $t('startDate') }} : {{this.$DateTimeService.GetDateFromISO(event.startDate)}}</div>
                                <div class="badge" :class="getEventSynchronizationStateBadgeColor(event.eventSyncState)" >{{ this.$t(event.eventSyncState)}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else class="d-flex justify-content-center align-items-center h-100">
            <NoData></NoData>
        </div>
    </div>
</template>

<script>
    import eventService from '../services/event.service';
    import AddEvent from '../components/events/AddEvent'; 
    import UserService from '../services/user.service';
    import NoData from '../components/NoData';

    export default {
        name: "EventsView",
        data() {
            return {
                searchQuery:'',
                events:[],
                filteredList:[],
            };
        },
        created:function() {
            this.refreshEventsList();
        },
        methods: {
            refreshEventsList: function() {
                eventService.getUserEvents(this.userId).then(lst => {
                        this.events = lst.data;
                        this.filteredList = lst.data;
                        this.searchQuery='';
                    }
                );
            },
            goToEvent: function(id){
                this.$router.push({ path: 'events/' + id });
            },
            filterEventList: function(){
                this.filteredList = this.events.filter(item => {
                    return item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
                });
            },
            eventSynchStateText: function(event){
                switch(event.eventSyncState){
                    case(0) : return this.$t("notSynch");
                    case(1) : return this.$t("syncInProgress");
                    case(2) : return this.$t("synchronized");
                }
            }
        },
        computed:{
            isSuperAdmin: function(){
                return UserService.isSuperAdmin();
            },
            isAdmin: function(){
                return UserService.isAdmin();
            },
            userId: function(){
                return UserService.getUserId();
            },
            activeEvents: function(){
                return this.filteredList.filter(x => !x.isArchived);
            },
            archivedEvents: function(){
                return this.filteredList.filter(x => x.isArchived);
            }
        },
        components:{ AddEvent, NoData }
    }
</script>

<style scoped>
    .k4-eventCard{ 
        cursor:pointer;
    }

    .k4-eventCard:hover{
        box-shadow: 0 0 .15rem var(--main-k4-primary-color)!important;
    }

    .k4-eventName {
        font-size: 1em;
    }
</style>