<template>
<div class="k4-page">
    <section class="row">
        <div class="col">
            <div class="title">
                <h2 class="k4-global-pageTitle mb-0">{{ $t('menuTasks') }}</h2>
            </div>
        </div>
    </section>

    <SectionComponent v-bind:title="$t('tasks')" icon="tasks">
        <div class="row">
            <div class="col-xl-6 col-12 d-flex justify-content-center">
                <span class="h4 text-black">{{$t("events")}}</span>
            </div>
            <div class="col-xl-6 col-12 d-flex justify-content-center">
                <span class="h4 text-black">{{$t("checkpoints")}}</span>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-6 col-12 my-2 d-flex flex-column" v-for="(task, index) in tasks" v-bind:key="task.id">
                <div v-if="index % 2 === 0 && isSmallScreen" class="my-3"></div>
                <div class="card shadow-sm k4-cards flex-grow-1">
                    <div class="card-body">
                        <div class="row h-100">
                            <p class="font-weight-bold k4-taskName h-50">{{task.name}}</p>
                            <div class="row">
                                <div class="col-3">
                                    <div class="badge py-1 px-2" :class="{ 
                                        'badge-success': task.jobState === 'RUN', 
                                        'badge-warning': task.jobState === 'WAITING', 
                                        'badge-secondary': task.jobState === 'STOP', 
                                        'badge-danger': task.jobState === 'ERROR', 
                                        'badge-skyblue': task.jobState === 'ON_QUEUE'
                                        }">{{ $t(task.jobState.toLowerCase())}}
                                    </div>
                                </div>

                                <div class="col-6" v-if="task.jobState != 'STOP'">{{ $t('next') }} : {{this.$DateTimeService.GetDateTimeFromISO(task.nextIteration, true)}}</div>
                                <div class="col-6" v-if="task.jobState == 'STOP'">{{ $t('next') }} : {{ $t('notProvided') }}</div>
                                <div class="col-3 k4-action">
                                    <div class="k4-action-button">
                                        <div class="" v-if="task.jobState != 'STOP'"><div v-on:click="taskStop(task.id)" class="pointer-click"><font-awesome-icon :icon="['fas','stop']" size="2x" /></div></div>
                                        <div class="" v-if="task.jobState == 'STOP'"><div  v-on:click="taskRun(task.id)" class="pointer-click"><font-awesome-icon :icon="['fas','play']" size="2x" /></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                
                </div>
            </div>        
        </div>
    </SectionComponent>
    <SectionComponent v-bind:title="$t('lastHistoric')" icon="history">
        <div class="card shadow-sm k4-cards">
            <div class="table-responsive">
                <table class="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th scope="col">{{ $t('name') }}</th>
                            <th scope="col">{{ $t('state') }}</th>
                            <th scope="col">{{ $t('duration') }} (s)</th>
                            <th scope="col">{{ $t('startDate') }}</th>
                            <th scope="col">{{ $t('endDate') }}</th>
                            <th scope="col">{{ $t('message') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="hist in historics" v-bind:key="hist.historicId">
                            <th scope="row">{{hist.name}}</th>
                            <td><span class="badge py-1 px-2" :class="{ 'badge-success': hist.state === 'OK', 'badge-danger': hist.state == 'ERROR'}">{{hist.state}}</span></td>
                            <td>{{hist.duration}} s</td>
                            <td>{{this.$DateTimeService.GetDateTimeFromISO(hist.startDate, true)}}</td>
                            <td>{{this.$DateTimeService.GetDateTimeFromISO(hist.endDate, true)}}</td>
                            <td>{{hist.message}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </SectionComponent>
</div>
</template>

<script>
    import BackgroundTaskService from '../services/backgroundTask.service';
    import SectionComponent from '../components/SectionComponent';
    import utilMixin from '../mixins/utils.mixins';
    import { mapState } from "vuex";

    export default {
        name:"BackgroundTaskView",
        mixins: [utilMixin],
        data : function (){
            return {
                timer: '',
                tasks : [],
                historics: [],
                timerError:0
            }
        },
        created:function(){
            this.refreshTaskDetails();
            this.refreshHistorics();
            this.timer = setInterval(() => {this.refreshTaskDetails(); this.refreshHistorics(); },5000);
        },
        beforeUnmount : function(){
            clearInterval(this.timer);
        },
        methods: {
            refreshTaskDetails : function() {
                BackgroundTaskService.getBackgroundTasks().then(
                    resp => {
                        var response = resp.status ? resp : resp.response; 
                        if(response.status == 200){
                            this.tasks = response.data.sort((a, b) => a.id - b.id);
                            console.log(this.tasks);
                            this.clearTimerError();
                        } else {
                            
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }
                    }
                ).catch(
                    error => {
                        
                        this.showToast(this.$t('error'),error,'danger');
                        this.addTimerError();
                    }
                );
            },
            refreshHistorics : function() {
                BackgroundTaskService.getHistory(25).then(
                    resp => {
                        var response = resp.status ? resp : resp.response; 
                        if(response.status == 200){
                            this.historics = response.data;
                        } else {
                            
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }
                    }
                ).catch(
                    error => {
                        
                        this.showToast(this.$t('error'),error,'danger'); 
                    }
                );
            },
            taskRun : function(jobId){
                BackgroundTaskService.startJob(jobId).then(
                    resp => {
                        var response = resp.status ? resp : resp.response; 
                        if(response.status == 200){
                            this.showToast(this.$t('info'), response.data);
                            this.refreshTaskDetails();
                        }else{
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }
                    }
                ).catch(
                    error => {
                        
                        this.showToast(this.$t('error'),error,'danger'); 
                    }
                );
            },
            taskStop : function(jobId){
                BackgroundTaskService.stopJob(jobId).then(
                    resp => {
                        var response = resp.status ? resp : resp.response; 
                        if(response.status == 200){
                            
                            this.showToast(this.$t('info'), response.data);
                            this.refreshTaskDetails();
                        }else{
                            
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }
                    }
                ).catch(
                    error => { 
                        
                        this.showToast(this.$t('error'),error,'danger'); 
                    }
                );
            },
            addTimerError : function(){
                if(++this.timerError >= 5){
                    clearInterval(this.timer);
                    
                    this.showToast(this.$t('error'), this.$t('refreshStopped'), 'danger');
                }
            },
            clearTimerError : function (){
                this.timerError = 0;
            }
        },
        computed : {
            ...mapState(["isSmallScreen"])
        },
        components : { SectionComponent }
    }
</script>

<style scoped>
    .k4-card-title{font-size: 22px;font-variant: small-caps;}

    .k4-cards{ 
        font-size: 12px;
        /* cursor:pointer; */
    }
    .k4-task-text{margin-top: 8px;}
    .k4-action{text-align: right;}
    .k4-action-button{margin: 5px;padding: 0 5px;}
    .k4-taskName {
        font-size: 1.2em;
    }
</style>