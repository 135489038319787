export const ENGLISH_TRANSLATIONS = {
    connection: "Connect to your space",
    login: "Login",
    invalidLogin: "Error. Please check your user/password.",        
    password: "Password",
    identification: "Identification",
    title: "K4ACCESS - EVENT ADMINISTRATION BACK-OFFICE",
    name: "Name",
    role: "Roles",
    email: "Email",
    pinCode: "Pin code",
    events: "Events",
    activeEvents: "Active Events",
    archivedEvents: "Archived Events",
    archivedEventInfo: "You are on an archived event. Please note that the content here is historical and only for information purposes.",
    validatePassword: "Verif Password",
    disconnect: "Disconnect",
    menuUser: "BO user authorization",
    menuTasks: "Background tasks",
    selectEvent: "Select your event",
    profile: "Profile",
    search: "Search...",
    startDate: "Start date",
    endDate: "End date",
    lastSync: "Last sync",
    addEvent: "Add Event",
    archiveEvent: "Archive Event",
    actionConfirmationMessage: "Are you sure you want to {action} {name} ? 🗑️ This action cannot be undone.",
    archiveEventSuccessMessage: "Your event has been gracefully archived. 🎉",
    addUser: "Add User",
    importEvent: "Import event",
    createUser: "Create user",
    userCreated: "User created",
    import: "Import",
    create: "Create",
    save: "Save",
    edit: "Edit",
    back: "Back",
    cancel: "Cancel",
    close: "Close",
    download: "Download",
    delete: "Delete",
    archive: "Archive",
    update: "Update",
    tasks: "Tasks",
    next: "Next",
    notProvided: "Not provided",
    lastHistoric: "Latest Historics",
    state: "State",
    duration: "Duration",
    message: "Message",
    NOT_SYNC: "not synch",
    PROGRESS_SYNC: "synch in progress",
    SYNC: "synchronized",
    synchro: "Synchro",
    authorizeUsers: "List of authorized users",
    dashboard: "Dashboard",
    generalInformation: "General Information",
    onSiteStaff: "On-site staff",
    generalStats: "General Statistics",
    eventsStats: "Event Checkpoints Statistics",
    deviceUserlistEmpty: "device user list is empty",
    checkpoints: "Checkpoints",
    blueActivated: "blue = activated",
    deleteUser: "Delete user",
    savedUser: "User saved",
    comfirmDelete: "Do you really want to delete",
    activateFor: "Active checkpoints for ",
    countCheckpoints: "Number of Checkpoints",
    countRegistered: "Known badges",
    countPresent: "Have Checked-In",
    searchOn : "Search on ID / LABEL / CATEGORY",
    searchOnCheck : "Search on BARCODE / NAME / DIRECTION / DATE / STATUS",
    searchOnBarcode : "Search on BARCODE / LABEL / TAGS",
    details: "Details",
    config: "Config",
    detailsFor: "Details for ",
    attendees: "Attendees",
    expected: "Expected",
    loading: "Loading",
    id: "Id",
    label:"Label",
    start:"Start",
    category:"Category",
    accessControl:"Access Control",
    checkedIn:"Checked-In",
    present:"Present",
    capacity:"Capacity",
    actions:"Actions",
    loadUnhauthCode: "Load unauthorized barcodes",
    barcode: "Barcode",
    lastUpdate: "Last update",
    openAccess: "Open Access",
    notSet: "Not Set",
    passages: "Passages",
    tags: "Tags",
    authorized: "Authorized",
    unauthorized: "Unauthorized",
    notAlive: "Not alive",
    location: "Location",
    isWaitForSignature: "Is wait for signature",
    resultDisplayDuration: "Result display duration",
    defaultNumberAllowed: "Default number of allow checked in",
    syncFirst: "Sync at first",
    syncFrequency: "Sync frequency",
    accessUnknowBarcode: "Access if unknow barcode",
    allowedRegistration: "Allowed registration",
    isInAndOut: "Is in and out",
    seconds: "seconds",
    people: "people",
    stop: "STOP",
    waiting: "WAITING",
    run: "RUN",
    error: "Error",
    info: "Info",
    warning: "Warning",
    refreshStopped: "Refresh has been stopped.",
    syncRunningWait: "Synch is running please wait ...",
    synchroKnowbarcode: "La Synchro connaît le code barres",
    noUserSelected: "No user selected",
    confirmedDeleteUser: "Confirmed delete user",
    hasBeenDeleted: " has been deleted.",
    hasBeenCreated: " has been created.",
    selectAtLeastOneCp: "Please select at least one checkpoint.",
    pleaseEnterName: "Please enter a Name.",
    pleaseEnterValidMail: "Please enter a valid Email.",
    passwordAreDifferent: "Passwords are different.",
    pleaseSelectARole: "Please select at least one role.",
    saveCheckpoint: "Checkpoint saved",
    checkIn:"Check",
    scans:"Scans",
    date:"Date",
    photoDisplay:"Photo display",
    photoDisplayNone:"None",
    photoDisplayPreload:"Preload",
    photoDisplayOnLoad:"Onload",
    statistics:"Statistics",
    statisticsMore:"More data",
    statisticsExport:"Export all data",
    rushHour: "Rush hour",
    rushCheckpoint: "Checkpoint Selection",
    rushRemoveSelection: "Remove selection",
    selectAll: "Select all",
    eventConfig: "Event configuration - ",
    timezone: "Timezone",
    timezoneSelect: "Timezone selection",
    saveEvent: "Event saved",
    pdfBanner: "PDF Banner ",
    pdfBannerInfo: "(max: 725x150 px)",
    RobotUserEnable: "Enable robot user",
    UserType: "User Type",
    RegularUser: "Regular User",
    RobotUser: "Robot User",
    pleaseSelect: "Please select ...",
    directionIN:"IN",
    directionOUT:"OUT",
    dayOfWork :"Day of work",
    export: "Export",
    exportScansRegister : "Publish scans",
    checkpointlistEmpty: "checkpoints list is empty",
    end: "End",
    warningTestEvent: "Warning: This event is currently in 'testing' phase.",
    productionMode: "Production Mode",
    keepData: "Keep all data",
    deleteData: "Remove all data",
    productionModePopUp1: "Are you sure you want to go in production mode? (All data will be erased)",
    productionModePopUp2: "Are you sure you want to go in production mode? (All data will be kept)",
    ok: "Ok",
    importFromList: "From list",
    importManual: "Manual",
    eventId: "Event ID",
    eventHash: "API Key",
    eventUrl: "API URL",
    success: "Success",
    startExportPDF: "Start of PDF export...",
    endExportPDF: "The PDF has been downloaded",
    welcome: "welcome",
    on: "on",
    group: "Group",
    createNewGroup: "Add a new group",
    searchGroup: "Type to search or add group",
    noDataToDisplay: "No Data to Display",
    allowManualAdditionalScans: "Allow manual additional scans",
    language: "Language",
    status: "Status",
    unknownBarcode: "Unknown Barcode",
    refused: "Refused",
    quantity: "Quantity",
    devicesLogsDownloadCenter: "Devices logs download center",
    lastRefresh: "Last Refresh"
}