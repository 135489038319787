<template>
    <div class="card shadow">
        <div class="card-body">
            <div class="row">
                <section class="col-12">
                    <div class="row">
                        <div class="col-lg-5">{{ $t("name") }}</div>
                        <div class="col-lg-7 my-auto">{{event.name}}</div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5">{{ $t("startDate") }}</div>
                        <div class="col-lg-7 my-auto">{{this.$DateTimeService.GetDateFromISO(event.startDate)}}</div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5">{{ $t("lastSync") }}</div>
                        <div class="col-lg-7 my-auto" v-if="event.lastSync">{{this.$DateTimeService.GetDateTimeFromISO(event.lastSync, true)}}</div>
                        <div class="col-lg-7 my-auto" v-if="!event.lastSync"><span class="badge badge-warning py-1 px-2">{{ $t("notSynch") }}</span></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5">{{ $t("timezone") }}</div>
                        <div class="col-lg-7 my-auto">(UTC {{event.utcOffset}}) {{ event.timezoneIso }}</div>
                    </div>
                </section>

                <div v-if="!this.event.isArchived" class="m-0 p-0">
                    <div class="d-flex justify-content-center my-5">
                        <Qrcode v-if="event.qrCode" :text="event.qrCode" :size="150" color="#2352a4"></Qrcode>
                    </div>

                    <section class="col-12">
                        <div class="d-flex">
                            <button class="btn btn-secondary w-50 mr-2" @click="showDetails()" v-if="isAdmin()"><font-awesome-icon icon="gear" size="1x" class="mx-1" />{{ $t('config') }}</button>
                            <ButtonExportPDF ref="ButtonExportPDF" class="w-50" @click.native="getInformation()"></ButtonExportPDF>
                        </div>

                        <div class="d-flex my-2">
                            <button :class="{'btn' : true, 'btn-primary' : true, 'w-50' : isKey4Admin(), 'w-100' : !isKey4Admin(), 'mr-2' : isKey4Admin() }" @click="exportScans()"><font-awesome-icon icon="upload" size="1x" />{{ $t('exportScansRegister') }}</button>
                            <button v-if="isKey4Admin()" class="btn btn-info w-50" @click="showLogFiles()"><font-awesome-icon icon="book" size="1x" />Logs</button>
                        </div>

                        <div>
                            <button v-if="canArchiveEvent" class="btn btn-danger col-12" @click="showDeletionConfirmationModal()"><font-awesome-icon :icon="['fas','box-archive']" />{{ this.$t("archiveEvent") }}</button>
                        </div>
                    </section>
                </div>                
            </div>
            <EventConfig ref="detailsModal" @synchroEvent="synchroEvent" />
            <GeneratePDF ref="eventPdf" @onEndExportPDF="onEndExportPDF" />
            <ModalLogs v-if="isKey4Admin()" ref="modalLogs" />
            <ModalActionConfirmation 
                ref="modalActionConfirmation" 
                :title="this.$t('archiveEvent')" 
                :message="this.$t('actionConfirmationMessage', { action: this.$t('archive').toLowerCase(), name : this.event.name })" 
                :action="this.$t('archive')"
                @onConfirmedAction="onConfirmedAction()"
            />
        </div>
    </div>
</template>

<script>
import Qrcode from 'vue-qrcode-component';
import EventService from '../../services/event.service';
import UserService from '../../services/user.service';
import KnowBarcodeService from '../../services/knowbarcode.service';
import EventConfig from './EventConfig';
import GeneratePDF from '../pdf/GeneratePDF.vue'
import ButtonExportPDF from "../pdf/ButtonExportPDF.vue";
import ModalLogs from './ModalLogs';
import ModalActionConfirmation from '../ModalActionConfirmation';

export default {
    name : 'DetailsEvent',
    props:['event'],
    components:{ Qrcode, EventConfig, GeneratePDF, ButtonExportPDF, ModalLogs, ModalActionConfirmation },
    data : function(){
        return {
            
        }
    },
    methods : {
        onConfirmedAction(){
            this.$refs.modalActionConfirmation.onStartAction();
            EventService.archiveEvent(this.event.id).then(
                res => {
                    var response = res.status ? res : res.response;
                    this.$refs.modalActionConfirmation.onEndAction();
                    this.$refs.modalActionConfirmation.closeModal();
                    if(response.status == 200){
                        this.showToast(this.$t('archiveEvent'), this.$t('archiveEventSuccessMessage'));
                        // Reload Page
                        setTimeout(function() {
                            location.reload();
                        }, 1000);
                    }
                    else {
                        this.showToast(this.$t('error'), response.data, 'danger');
                        this.$refs.modalActionConfirmation.onEndAction();
                    }
                }
            );
        },
        onEndExportPDF(){
            this.$refs.ButtonExportPDF.onEndExportPDF();
        },
        synchroCheckpoints : function(){
            this.$emit('click_SynchroEvent');
        },
        synchroEvent : function(){
            this.$emit('synchroEvent');
        },
        isKey4Admin: function(){
            return UserService.isSuperAdmin();
        },
        isAdmin : function() {
            var isAdmin = UserService.isAdmin();
            return isAdmin ? isAdmin : false ;
        },
        showDetails : function() {
            this.$refs.detailsModal.showModal();
        },
        showLogFiles: function(){
            this.$refs.modalLogs.showModal();
        },
        showDeletionConfirmationModal: function(){
            this.$refs.modalActionConfirmation.showModal();
        },
        getInformation: function() {
            this.$refs.ButtonExportPDF.onStartExportPDF();
            this.$refs.eventPdf.createPDF();
        },
        exportScans: function() {
            
            this.showToast('Exporting', 'Exporting scans to the Register in-progress..');

            KnowBarcodeService.exportAllScans(this.eventId).then(
                resp =>{
                    var response = resp.status ? resp : resp.response; 
                    if(response.status == 200){
                        if (response.data == true){
                            this.showToast('Success', 'All scans have been successfully exported to the Register!');
                        }
                        else {
                            
                            this.showToast('Error', 'An error occured during the export. Please contact an administrator.', 'danger');
                        }
                    } else {
                        
                        this.showToast('Error', response.data, 'danger');
                    }
                }
            ).catch(
                error => {
                    this.showToast('Error', error, 'danger');
                }
            );
        },
        showToast: function(title, body, variant = 'default'){
            this.$bvToast.toast(body, {
                title: title ,
                autoHideDelay: 5000,
                variant: variant,
                appendToast: false
            });
        }
    },
    computed: {
        eventId: function() {
            return this.$route.params.id;
        },
        canArchiveEvent : function(){
            return UserService.hasRight("CanArchiveEvent");
        }
    }
}
</script>

<style scoped>
    .k4-actionButton {
        font-size: 14px;
    }
</style>