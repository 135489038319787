<template>
    <b-modal v-model="isShowModal" v-bind:title="title">
        {{ message }}

        <template #modal-footer>
            <div class="w-100">
                <button type="button" @click="isShowModal = false" class="btn btn-outline-secondary mx-1">{{$t("cancel")}}</button>
                <button :disabled="isActionInProgress" type="button" @click="this.$emit('onConfirmedAction')" class="btn btn-danger mx-1">
                    <div class="d-flex align-items-center justify-content-center">
                        <div v-if="isActionInProgress" class="spinner-grow spinner-grow-sm mx-2" role="status">
                            <span class="sr-only"></span>
                        </div>
                        <div>{{ action }}</div>
                    </div>
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    export default {
        name:"ModalActionConfirmation",
        props: ["title", "message", "action"],
        data : function() {
            return {
                isShowModal: false,
                isActionInProgress: false
            };
        },
        methods: {                
            showModal: function(){
                this.isShowModal = true;
            },
            closeModal: function(){
                this.isShowModal = false;
            },
            onStartAction(){
                this.isActionInProgress = true;
            },
            onEndAction() {
                this.isActionInProgress = false;
            },
        }
    }
</script>