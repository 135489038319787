<template>
    <b-modal v-model="show" v-bind:title="$t('productionMode')" v-bind:ok-title="$t('ok')" v-bind:cancel-title='$t("cancel")' @ok="updateProductionMode" >
        <div v-if="eraseAllData">{{ $t("productionModePopUp1") }}</div>
        <div v-else>{{ $t("productionModePopUp2") }}</div>
    </b-modal>
</template>
<script>
import EventService from '../../services/event.service';
import utilMixin from '../../mixins/utils.mixins';

export default {
    name:"ProductionMode",
    mixins: [utilMixin],
    data : function() {
        return {
            show: false,
            eraseAllData: false,
            eventId: 0,
            user: {}
        };
    },
    created : function(){
    },
    mounted: function() {
    },
    methods: {                
        showModal: function(){
            this.show = true;
        },
        updateProductionMode: function(){
            EventService.updateProductionMode(this.eventId, this.eraseAllData).then(
                resp =>{
                    var response = resp.status ? resp : resp.response; 
                    if(response.status == 200){
                        
                        this.showToast('Success', response.data);
                        this.$emit('update_production_ok');
                    } else {
                        
                        this.showToast('Error', response.data, 'danger');
                    }
                }
            ).catch(
                error => {
                    
                    this.showToast('Error', error, 'danger');
                }
            );
        }
    },
    computed: {
    }
}
</script>