import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL_BASE + 'api/admin/events/';

class EventService{
    archiveEvent(eventId){
        return axios.delete(API_URL + eventId + "/archive", { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getAllLogFiles(eventId){
        return axios.get(API_URL + eventId + "/log/files",{ headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getLogFileContent(eventId, date, deviceId){
        return axios.get(API_URL + eventId + "/log/" + date + "/" + deviceId, { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getAllEvents(){
        return axios.get(API_URL + "all",{ headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getUserEvents(userId){
        return axios.get(API_URL + "user/" + userId, { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getEventDetails(eventId){
        return axios.get(API_URL + eventId,{ headers : authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getEventStats(eventId){
        return axios.get(API_URL + eventId + '/stats/',{ headers : authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getAllTimezones() {
        return axios.get(API_URL + "timezones/",{ headers : authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    updateConfig(eventId, model){
        return axios.put(API_URL + eventId + '/config/', model,{ headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    updateProductionMode(eventId, query){
        return axios.get(API_URL + eventId + '/production/' + query,{ headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getAutoComplete(query){
        return axios.get(API_URL + 'search/' + query, {headers : authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getImportEventList(query){
        return axios.get(API_URL + 'import/' + query, {headers : authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    putUserEvents(){
        
    }

    importEvent(event){
        return axios.put(API_URL, event, {headers : authHeader()}).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    importEventManual(event){
        return axios.put(API_URL + 'manual/', event, {headers : authHeader()}).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }
}

export default new EventService();