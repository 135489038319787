<template>
    <div>
        <b-modal v-model="modalShow" scrollable v-bind:title="$t('activateFor') + user.name" size="xl">
            <div class="container-fluid">
                <div class="row pb-2">
                    <div class="col-lg-4">{{ $t("name") }}</div>
                    <div class="col-lg-8"><input type="text" class="form-control" v-model="user.name" disabled></div>
                </div>
                <div class="row pb-2">
                    <div class="col-lg-4">{{ $t("pinCode") }}</div>
                    <div class="col-lg-8"><input type="text" class="form-control" v-model="user.pin" disabled></div>
                </div>
                <div class="row pb-2">
                    <div class="col-lg-4">{{ $t("checkpoints")}}</div>
                    <div class="col-lg-8 text-primary">{{ $t("blueActivated")}}</div>    
                </div>
                <div class="row">
                    <div class="col-12 p-1" v-for="chpt in checkpoints" :key="chpt.id">
                        <div class="card p-2 text-center k4-pointer" :class="{'k4-selected' : isSelected(chpt)}" v-on:click="!this.isReadOnly && changeCheckpoint(chpt)" >
                            <div class="row">
                                <div class="col-10">{{chpt.name}}</div>
                                <div class="col-2">{{chpt.category}}</div>   
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <template v-slot:modal-footer>
                <button class="btn btn-outline-secondary" @click="modalShow = !modalShow">{{ $t("cancel")}}</button>
                <button v-if="!this.isReadOnly" class="btn btn-danger" @click="askToDelete" >{{ $t("delete")}}</button>
                <button v-if="!this.isReadOnly" class="btn btn-success" @click="updateUserCheckpoint">{{ $t("update")}}</button>
            </template>
        </b-modal>
        
        <b-modal v-if="user" v-model="confirmModal.show" v-bind:title="$t('deleteUser')">
            {{ $t("comfirmDelete") }} {{user.name}} ? 

            <template #modal-footer>
                <div class="w-100">
                    <button type="button" @click="confirmModal.show = false" class="btn btn-outline-secondary mx-1">{{$t("cancel")}}</button>
                    <button type="button" @click="deleteUser" class="btn btn-danger mx-1">{{$t('delete')}}</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import CheckpointService from '../../services/checkpoint.service';
    import UserService from '../../services/user.service';
    import utilMixin from '../../mixins/utils.mixins';
    import { mapGetters } from 'vuex';

    export default {
        name:"UpdatePinUser",
        mixins: [utilMixin],
        props: ["isReadOnly"],
        data: function(){
            return {
                modalShow:false,
                user:{},
                selectedCheckpoints: [],
                confirmModal:{
                    show:false,
                }
            }
        },
        computed: {
            ...mapGetters({
                checkpoints: 'getCheckpoints'
            })
        },
        watch:{
            user: function(val){
                if(val && val.eventId){
                    this.selectedCheckpoints = [];
                    this.getUserCheckpoints();
                }
            },
            modalShow: function(val){
                if(val == false){
                    this.user={};
                }
            }
        },
        methods:{
            getUserCheckpoints: function(){
                if(this.user && this.user.eventId){
                    // change list of selected checkpoint 
                    this.selectedCheckpoints = [];
                    CheckpointService.getUserCheckpoint(this.user).then(
                        resp => {
                            var response = resp.status ? resp : resp.response; 
                            if(response.status == 200){
                                this.selectedCheckpoints = response.data;
                                if(this.selectedCheckpoints.length==0){
                                    
                                    this.showToast(this.$t('warning'), this.$t('selectAtLeastOneCp'), 'warning');
                                }
                            }
                            else {
                                this.showToast(this.$t('error'), response.data, 'danger');
                            }
                        }
                    )
                }
            },
            showModal: function(){
                this.modalShow=true;
            },
            changeCheckpoint: function(ckpt){
                var index = this.selectedCheckpoints.findIndex(r => r.id == ckpt.id);
                if(index<0){
                    this.selectedCheckpoints.push(ckpt);
                }else{
                    this.selectedCheckpoints.splice(index,1);
                }
            },
            isSelected:function(checkpoint){
                return this.selectedCheckpoints.findIndex(c => c.id == checkpoint.id) >= 0;
            },
            updateUserCheckpoint : function(){
                var userCheckpoints = [];
                this.selectedCheckpoints.forEach(item => userCheckpoints.push({userId: this.user.id, checkpointId: item.id}));
                CheckpointService.putUserCheckpoints(userCheckpoints, this.user.eventId).then(
                    resp => {
                        var response = resp.status ? resp : resp.response;
                        if(response.status == 200) {
                            
                            response.data.forEach(item => this.showToast(this.$t('update'), item));
                            this.modalShow=false;
                            this.$emit("action_ok");
                        } else {
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }
                    }
                ).catch(error => {
                    this.showToast(this.$t('error'), error, 'danger');
                });
            },
            askToDelete:function(){
                this.confirmModal.show = true;
            },
            deleteUser: function(){
                if (this.user){
                    UserService.deleteUser(this.user).then(
                        resp => {
                            var response = resp.status ? resp : resp.response; 
                            if(response.status == 200){
                                this.showToast(this.$t('confirmedDeleteUser'), this.user.name + this.$t('hasBeenDeleted'));
                                this.$emit("delete_ok");
                                this.modalShow = false;
                                this.confirmModal.show = false;
                            }else {
                                this.showToast(this.$t('error'), response.data, 'danger');
                            }
                        }
                    )
                }
            }
        }
    }
</script>