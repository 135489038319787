<template>
    <!-- Use section html tag and css class pdf-item to avoid elements being cut -->
    <div v-if="pdfCreate">
      <VueHtml2pdf
         :show-layout="false"
         :float-layout="true"
         :enable-download="true"
         :preview-modal="false"
         :paginate-elements-by-height="1400"
         :pdf-quality="2"
         :manual-pagination="true"
         pdf-format="a4"
         pdf-orientation="portrait"
         pdf-content-width="775px"
         :html-to-pdf-options="htmlToPdfOptions"
         ref="html2Pdf"

         @startPagination="onStartPagination"
         @progress="onProgress"
     >
         <template v-slot:pdf-content>
            <img src="../../assets/key4access_logo_text_blue.png" class="k4-global-PdfHeaderLogo mx-auto d-block m-2">
            
             <div v-if="event.bannerPDF64 != ''" class="card shadow" style="padding-left: 25px">
                 <div class="card-body">
                     <div class="container">
                         <div class="row">
                             <img :src="event.bannerPDF64" width="725" height="150">
                         </div>
                     </div>
                 </div>
             </div>
             <div class="card shadow" style="padding-left: 25px">
                 <div class="card-body">
                     <div class="container">
                         <div class="row">
                             <h1>{{event.name}}</h1>
                         </div>
                         <div class="position-absolute" style="right: 0px; padding-top: 25px">
                             <Qrcode v-if="event.qrCode" :text="event.qrCode" :size="115" color="#2352a4"></Qrcode>
                         </div>
                         <div class="row" style="padding-top: 25px">
                             <h3 class="py-2 mb-2 w-100">
                                 <i class="position-absolute">
                                     <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-info-circle fa-w-16"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" class=""></path></svg>
                                 </i>
                                 <span class="d-inline-block pl-5 w-75">{{ $t("generalInformation") }}</span>
                             </h3>
                         </div>
                         <div class="row" style="padding-top: 10px">
                             <div class="col-12">
                                 <div class="row">
                                     <div class="col-lg-3">{{ $t("startDate") }}</div>
                                     <div class="col-lg-9">{{this.$DateTimeService.GetDateFromISO(event.startDate)}}</div>
                                 </div>
                                 <div class="row">
                                     <div class="col-lg-3">{{ $t("timezone") }}</div>
                                     <div class="col-lg-9">(UTC {{event.utcOffset}}) {{ event.timezoneIso }}</div>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
             <div class="card shadow" style="padding-left: 25px">
                 <div class="card-body">
                     <div class="container">
                         <div class="row">
                             <h3 class="py-2 mb-2 w-100">
                                 <i class="position-absolute">
                                     <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="address-book" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-address-book fa-w-14"><path fill="currentColor" d="M436 160c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20zm-228-32c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H118.4C106 384 96 375.4 96 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2z" class=""></path></svg>
                                 </i>
                                 <span class="d-inline-block pl-5 w-75">{{ $t("onSiteStaff") }}</span>
                             </h3>
                         </div>
                        <div class="row">
                            <div class="col-12" v-if="users.length==0">
                                <div class="alert alert-warning">{{ $t("deviceUserlistEmpty") }}</div> 
                            </div>
                            <div class="col-xl-4 col-sm-6 px-0 pr-2 pb-2" v-for="user in users" :key="user.id">
                                <section class="pdf-item card shadow-sm k4-clickable container h-100">
                                    <div class="row " v-if="!user.isRobotUser">
                                        <div class="col-8 text-center p-2 k4-badge-absolute">
                                            <span>{{user.name}}</span>
                                        </div>
                                        <div class="col-4 text-center p-2 bg-k4-primary text-white">{{user.pin}}</div>
                                    </div>
                                    <div class="row" v-else>
                                        <div class="col-8 text-center p-2 k4-badge-absolute-robot">
                                            <span><font-awesome-icon style="color: #598cd9;" :icon="['fas','robot']" size="1x" /> {{user.name}}</span>
                                        </div>
                                        <div class="col-4 text-center p-2 bg-k4-robotuser text-white">{{user.pin}}</div>
                                    </div>
                                </section>
                            </div>
                        </div>              
                     </div>
                 </div>
             </div>
        </template>
     </VueHtml2pdf>
    </div>
 </template>
 
 <script>
    import Qrcode from 'vue-qrcode-component';
    import VueHtml2pdf from 'vue-html2pdf'
    import UserService from '../../services/user.service';
    import utilMixin from '../../mixins/utils.mixins';
    import { mapGetters } from 'vuex';
    
    export default {
        name: "generatePDF",
        mixins: [utilMixin],
        components: {
            VueHtml2pdf, 
            Qrcode
        },
        data : function (){
            return {
                users : [],
                bannerImage : "", 
                pdfCreate : false,
                htmlToPdfOptions: {
                    margin: [10, 0, 10, 0],
                    filename: 'KEY4ACC_Information',
                    image: {type: 'jpeg',quality: 0.98},
                    html2canvas: {
                        scale: 2,
                        bottom: 20
                    },
                    pagebreak: { mode: ['css']},
                    jsPDF: {
                        unit: 'mm',
                        orientation: 'portrait'
                    }
                }
            }
        },
        computed : {
            ...mapGetters({
                event: 'getEvent'
            }),
            eventId : function() {
                return this.$route.params.id;
            }
        },
        methods: {
            createPDF : function() {
                this.pdfCreate = true;
                this.fetchEventUsers();
            },
            generateReport () {
                this.$refs.html2Pdf.generatePdf();
                setTimeout(function() {
                    this.pdfCreate = false;
                }.bind(this), 100);
            },
            fetchEventUsers : function(){
                UserService.getUsersByEvent(this.event.id).then(
                    resp => {
                        var response = resp.status ? resp : resp.response;
                        if(response.status == 200){
                            this.users = response.data;
                            this.generateReport();
                        }
                    },
                    error => {
                        this.showToast(this.$t('error'), error.data,'danger');
                    }
                );
            },
            onStartPagination(){
                this.$emit('onStartExportPDF');
            },
            onProgress(){
                this.$emit('onEndExportPDF');
            }
        },
    }
 </script>

 <style scoped>
    .card{overflow: visible;}
    .k4-pin{
        background-color: var(--dark);
        color:var(--main-top-font-color);
    }
    .k4-badge-absolute{position: relative;border: solid 0.1em var(--main-k4-primary-color);}
    .k4-badge-absolute .badge-light{background-color: white;}
    .k4-badge-absolute>.badge{position: absolute; top:-6px;left:-6px;}
    
    .k4-badge-absolute-robot{position: relative;border: solid 0.1em #598cd9;}
    .k4-badge-absolute-robot .badge-light{background-color: white;}
    .k4-badge-absolute-robot>.badge{position: absolute; top:-6px;left:-6px;}
    
    .k4-clickable{cursor: pointer;}
    
    .pdf-item {
        page-break-inside: avoid !important;
    }
 </style>